// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [

  // SuperAdmin
  {
    subheader: 'super admin',
    role: "superadmin",
    items: [
      {
        title: 'users',
        path: '/super-admin/users',
        icon: ICONS.user,
        children: [
          { title: 'All Users', path: '/super-admin/users/all' },
          { title: 'Create User', path: '/super-admin/users/create' },
        ],
      },
    ],
  },

  // Overview
  // ----------------------------------------------------------------------
  {
    subheader: 'overview',
    role: "user",
    items: [
      {
        title: 'dashboard',
        path: '/app/dashboard',
        icon: ICONS.dashboard,
      },
      {
        title: 'transactions',
        path: '/app/transactions/all',
        icon: ICONS.banking,
      },
    ],
  },


  // Items
  // ----------------------------------------------------------------------
  {
    subheader: 'Item Catalog',
    role: "user",
    items: [
      {
        title: 'items',
        path: '/app/items',
        icon: ICONS.ecommerce,
        children: [
          { title: 'All Items', path: '/app/items/all-items' },
          { title: 'Create Item', path: '/app/items/create-item' },
        ],
      },
      {
        title: 'categories',
        path: '/app/categories',
        icon: ICONS.menuItem,
        children: [
          { title: 'All Categories', path: '/app/categories/all-categories' },
          { title: 'Create Category', path: '/app/categories/create-category' },
        ],
      },
    ],
  },


  // Reports
  // ----------------------------------------------------------------------
  {
    subheader: 'reports',
    role: "user",
    items: [
      // {
      //   title: 'daily reports',
      //   path: '/app/daily-reports',
      //   icon: ICONS.analytics,
      // },
      {
        title: 'sales reports',
        path: '/app/sales-reports',
        icon: ICONS.invoice,
      },
      {
        title: 'tax reports',
        path: '/app/tax-reports',
        icon: ICONS.invoice,
      },
    ],
  },
];

export default navConfig;
