import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { Client, Account } from "appwrite";
import { APPWRITE_API } from "../config";

const client = new Client()
    .setEndpoint(APPWRITE_API.endpoint)
    .setProject(APPWRITE_API.projectId);

const account = new Account(client);

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const reducer = (state, action) => {
    if (action.type === 'INITIALISE') {
        const { isAuthenticated, user } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    }

    return state;
};

const AuthContext = createContext({
    ...initialState,
    method: 'appwrite',
    login: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    updateRecovery: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const [profile, setProfile] = useState(null);

    useEffect(() => {
        async function getUser() {
            await account.get().then((user) => {
                setProfile({
                    uid: user.$id,
                    email: user.email,
                    name: user.name,
                    roles: user.labels,
                });
                dispatch({
                    type: 'INITIALISE',
                    payload: { isAuthenticated: true, user },
                });
            }).catch((err) => {
                dispatch({
                    type: 'INITIALISE',
                    payload: { isAuthenticated: false, user: null },
                });
            });
        }

        getUser();

    }, [dispatch]);

    const login = async (email, password) => {
        await account.createEmailSession(email, password);
        await account.get().then((user) => {
            setProfile({
                uid: user.$id,
                email: user.email,
                name: user.name,
                roles: user.labels,
            });
            dispatch({
                type: 'INITIALISE',
                payload: { isAuthenticated: true, user },
            });
        }).catch((err) => {
            dispatch({
                type: 'INITIALISE',
                payload: { isAuthenticated: false, user: null },
            });
        });
    }
    const resetPassword = (email) => account.createRecovery(email, "https://app.bikpays.com/auth/password-recovery");
    const logout = async () => {
        await account.deleteSession('current');
        dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
        });
    }
    const changePassword = async (currentPassword, newPassword) => {
        await account.updatePassword(newPassword, currentPassword);
    }

    const updateRecovery = async (userId, secret, password, confirmPassword) => {
        await account.updateRecovery(userId, secret, password, confirmPassword);
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'appwrite',
                user: {
                    id: profile?.uid,
                    email: profile?.email,
                    fullName: profile?.name,
                    roles: profile?.roles,
                },
                login,
                resetPassword,
                logout,
                changePassword,
                updateRecovery,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
