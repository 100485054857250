import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/app')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { pathname } = useLocation();
  return useRoutes([
    {
      path: '/',
      element: <RoleBasedGuard isRoute />,
    },

    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: 'password-recovery',
          element: (
            <GuestGuard>
              <PasswordRecovery />
            </GuestGuard>
          ),
        }
      ],
    },

    // Super Admin Routes
    {
      path: 'super-admin',
      element: pathname === '/super-admin' ? (<Navigate to="/super-admin/users/all" replace />) : (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'users',
          children: [
            {
              path: "all",
              element: (
                <RoleBasedGuard role={'superadmin'} hasContent>
                  <Users />
                </RoleBasedGuard>
              )
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard role={'superadmin'} hasContent>
                  <CreateUser />
                </RoleBasedGuard>
              )
            },
            {
              path: "edit",
              element: (
                <RoleBasedGuard role={'superadmin'} hasContent>
                  <EditUser />
                </RoleBasedGuard>
              )
            },
          ],
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'app',
      element: pathname === '/app' ? (<Navigate to="/app/dashboard" replace />) : (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: (
            <RoleBasedGuard role={'user'} hasContent>
              <Dashboard />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'changepassword',
          element: (
            <ChangePassword />
          ),
        },
        {
          path: 'transactions',
          children: [
            {
              path: "all",
              element: (
                <RoleBasedGuard role={'user'} hasContent>
                  <Transactions />
                </RoleBasedGuard>
              )
            },
            {
              path: "view",
              element: (
                <RoleBasedGuard role={'user'} hasContent>
                  <ViewTransaction />
                </RoleBasedGuard>
              )
            },
          ],
        },
        {
          path: 'items',
          children: [
            {
              path: 'all-items',
              element: (
                <RoleBasedGuard role={'user'} hasContent>
                  <Items />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create-item',
              element: (
                <RoleBasedGuard role={'user'} hasContent>
                  <CreateItem />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit-item',
              element: (
                <RoleBasedGuard role={'user'} hasContent>
                  <EditItem />
                </RoleBasedGuard>
              )
            },
          ],
        },
        {
          path: 'categories',
          children: [
            {
              path: 'all-categories',
              element: (
                <RoleBasedGuard role={'user'} hasContent>
                  <Categories />
                </RoleBasedGuard>
              )
            },
            {
              path: 'create-category',
              element: (
                <RoleBasedGuard role={'user'} hasContent>
                  <CreateCategory />
                </RoleBasedGuard>
              )
            },
            {
              path: 'edit-category',
              element: (
                <RoleBasedGuard role={'user'} hasContent>
                  <EditCategory />
                </RoleBasedGuard>
              )
            },
          ],
        },
        // {
        //   path: 'daily-reports',
        //   element: (
        //     <DailyReports />
        //   )
        // },
        {
          path: 'sales-reports',
          element: (
            <RoleBasedGuard role={'user'} hasContent>
              <SalesReports />
            </RoleBasedGuard>
          )
        },
        {
          path: 'tax-reports',
          element: (
            <RoleBasedGuard role={'user'} hasContent>
              <TaxReports />
            </RoleBasedGuard>
          )
        }
      ],
    },

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ChangePassword = Loadable(lazy(() => import('../pages/ChangePassword')));
const PasswordRecovery = Loadable(lazy(() => import('../pages/auth/PasswordRecovery')));
// OVERVIEW
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Transactions = Loadable(lazy(() => import('../pages/transactions/Transactions')));
const ViewTransaction = Loadable(lazy(() => import('../pages/transactions/ViewTransaction')));
// ITEMS
const Items = Loadable(lazy(() => import('../pages/items/Items')));
const Categories = Loadable(lazy(() => import('../pages/categories/Categories')));
const CreateItem = Loadable(lazy(() => import('../pages/items/CreateItem')));
const CreateCategory = Loadable(lazy(() => import('../pages/categories/CreateCategory')));
const EditItem = Loadable(lazy(() => import('../pages/items/EditItem')));
const EditCategory = Loadable(lazy(() => import('../pages/categories/EditCategory')));
// Reports
// const DailyReports = Loadable(lazy(() => import('../pages/reports/Daily')));
const SalesReports = Loadable(lazy(() => import('../pages/reports/Sales')));
const TaxReports = Loadable(lazy(() => import('../pages/reports/Taxes')));
// SUPER ADMIN
const Users = Loadable(lazy(() => import('../pages/users/Users')));
const CreateUser = Loadable(lazy(() => import('../pages/users/CreateUser')));
const EditUser = Loadable(lazy(() => import('../pages/users/EditUser')));
